<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
          <b-overlay :show="loading">
              <b-row>
                <b-col md="12" lg="12" sm="12">
                  <b-card no-body>
                      <b-overlay>
                        <b-row>
                          <b-col v-if="load">
                           <ActionForm :previousRouteName="previousRouteName" :previousRouteParams="previousRouteParams" :transferData="transferData" ref="ActionForm"/>
                          </b-col>
                        </b-row>
                      </b-overlay>
                  </b-card>
                </b-col>
              </b-row>
          </b-overlay>
      </template>
    </iq-card>
  </b-container>
</template>
<script>
import ActionForm from './ActionForm.vue'
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { stepFormData } from '../../../api/routes'
import { isNotEmpty } from '@amcharts/amcharts4/.internal/core/utils/Utils'
export default {
  props: ['stepChange'],
  components: {
    ActionForm
  },
  data () {
    return {
      transferData: {},
      previousRouteName: '',
      previousRouteParams: '',
      loading: false,
      load: false,
      checkApp: false
    }
  },
  created () {
    this.step_id = parseInt(this.$route.query.step_id)
    this.loadFromWithData()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.previousRouteName = from.name
      vm.previousRouteParams = from.params || {}
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.previousRouteName = from.name
    this.previousRouteParams = from.params || {}
    next()
  },
  methods: {
    loadFromWithData () {
      const formId = this.$route.query.form_id
      const serviceId = this.$route.query.service_id
      const stepId = this.$route.query.step_id
      const params = {
        id: formId,
        service_id: serviceId,
        step_id: stepId,
        applicationID: parseInt(this.$route.query.application_id)
      }
      this.loading = true
      RestApi.getData(licenseRegistrationServiceBaseUrl, stepFormData, params).then(response => {
        this.loading = false
        if (isNotEmpty(response.data)) {
            this.transferData = response.data
            this.load = true
            if (response.data.applicationData.length) {
              this.checkApp = true
            } else {
              this.checkApp = false
            }
          }
      })
    },
    backStep () {
      this.$router.go(-1)
    }
  }
}
</script>
